import { MenuData } from '@/types/api';

export enum ViewType {
  None,
  Modal,
  Blank,
}

export enum SiteHeader {
  OurSites = 'OurSites',
  ForWork = 'ForWork',
  BugReport = 'BugReport',
  FnfNews = 'FnfNews',
  Approval = 'Approval',
}

export const bugReportLoadMaps: MenuData[] = [
  {
    name: '오류 불편 신고',
    link: 'https://fnf.atlassian.net/servicedesk/customer/portal/50/group/154/create/813',
    action: 'newTab',
    isDisplay: true,
    childData: [],
  },
];

export const mockData: MenuData[] = [
  {
    name: 'Approval',
    isDisplay: true,
    gridNumber: 0,
    childData: [
      {
        name: 'Approval',
        link: 'https://portal.fnf.co.kr/approval',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'Company',
    isDisplay: true,
    gridNumber: 1,
    childData: [
      {
        name: '사규',
        link: 'https://pop.fnf.co.kr/intranet/intranet/company',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: '인사조회',
        link: 'https://portal.fnf.co.kr/#person_open',
        action: 'layer',
        isDisplay: true,
        childData: [],
      },
      {
        name: '조직도',
        link: 'https://portal.fnf.co.kr/#person_open',
        action: 'layer',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'Social',
    isDisplay: true,
    gridNumber: 2,
    childData: [
      {
        name: 'F&F News',
        link: 'https://portal.fnf.co.kr/social',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Trend & Issue',
        link: 'https://portal.fnf.co.kr/social',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Community',
        link: 'https://portal.fnf.co.kr/social',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'HR',
    isDisplay: true,
    gridNumber: 3,
    childData: [
      {
        name: 'Finstagram',
        link: 'https://fin.fnf.co.kr/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Trend & Issue',
        link: 'https://fnf.ubob.com/Account/Login',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Work T.O.K',
        link: 'https://hr-dashboard.fnf.co.kr/login',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'IT',
    isDisplay: true,
    gridNumber: 4,
    childData: [
      {
        name: 'IT Service',
        link: 'https://fnf.atlassian.net/servicedesk/customer/portal/50',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Office 365',
        link: 'https://www.office.com/?auth=2',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Power BI',
        link: 'https://app.powerbi.com/home?experience=power-bi',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: '법무',
    isDisplay: true,
    gridNumber: 0,
    childData: [
      {
        name: 'E-Law',
        link: 'https://fnf.buptle.app/get_dashboard_view/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: '총무',
    isDisplay: true,
    gridNumber: 1,
    childData: [
      {
        name: 'L.I.F.E',
        link: 'https://i.fnf.co.kr/intranet/intranet/life',
        isDisplay: true,
        childData: [],
      },
      {
        name: '도서',
        link: 'https://pop.fnf.co.kr/intranet/books',
        isDisplay: true,
        childData: [],
      },
      {
        name: '동호회',
        link: 'https://web.yammer.com/main/feed',
        action: 'layer',
        isDisplay: true,
        childData: [],
      },
      {
        name: '리조트',
        link: 'https://pop.fnf.co.kr/intranet/condo',
        action: 'layer',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: '회계',
    isDisplay: true,
    gridNumber: 2,
    childData: [
      {
        name: 'SA',
        link: 'https://eas.fnf.co.kr/eaccounting.html',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'OUR SITES',
    isDisplay: true,
    gridNumber: 3,
    childData: [
      {
        name: 'F&F',
        link: 'https://www.fnf.co.kr/pages/main/main.php',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'COLLECTED',
        link: 'https://www.collected.co.kr/pages/index.php',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Jardin Perdu',
        link: 'https://www.jardinperdu.com/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'MLB',
        link: 'https://www.mlb-korea.com/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Discovery-Expedition',
        link: 'https://www.discovery-expedition.com/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'DUVETICA',
        link: 'https://www.duvetica.co.kr/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'SUPRA',
        link: 'https://supra-korea.com/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Sergio Tacchini',
        link: 'https://www.sergiotacchini.co.kr/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'Banila Co',
        link: 'https://www.banila.com/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
  {
    name: 'WORK',
    isDisplay: true,
    gridNumber: 4,
    childData: [
      {
        name: 'F&F Influencer',
        link: 'https://influenceradmin.fnf.co.kr/login',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'GOMS',
        link: 'https://goms.fnf.co.kr/login.do',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'GSCM',
        link: 'https://gscm.fnf.co.kr/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'MERP',
        link: 'https://erp.fnf.co.kr/login.do',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'SERP',
        link: 'https://serp.fnf.co.kr/login.do',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'SMS',
        link: 'https://sms.fnf.co.kr/login',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'PLM',
        link: 'https://plm.fnf.co.kr/WebAccess/login.html',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: 'VARCO Art',
        link: 'https://www.fnf.art.varco.ai/',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: '매장 재고조사/정산',
        link: 'https://count.fnf.co.kr/spLogin.do',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
      {
        name: '유로모니터',
        link: 'https://sso.euromonitor.com/sp/startSSO.ping?SpSessionAuthnAdapterId=spopentoken',
        action: 'newTab',
        isDisplay: true,
        childData: [],
      },
    ],
  },
];
