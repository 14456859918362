export const copyToClipboard = (start: number, end: number, id: string) => {
  //TODO : NEED SOMETHING NEW LOGIC ?!?
  const range = new Range();

  const temp = document.getElementById(id);

  if (temp) {
    range.setStart(temp, start);
    range.setEnd(temp, end);

    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(range);

    document.execCommand('copy');
    document.getSelection()?.removeAllRanges();
    alert('클립보드에 복사되었습니다. 원하는 곳에 붙여넣기 하여 사용해주세요.');
  }
};
export const copyClipBoardText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    return false;
  }
};

export const scrollToElement = (element: HTMLElement, offset = 0) => {
  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  }
};
